<template>
    <v-container fluid="">
        <v-row>
            <v-col>
                <v-dialog v-model="dateRangeDialog" width="700" transition="dialog-bottom-transition">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <h3>دیاریکردی ماوە</h3>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn text @click="search">
                                    <v-icon>mdi-magnify</v-icon>
                                    گەڕان
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-container fluid>
                            <v-form ref="searchForm">
                                <v-row>
                                    <v-col>
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field @focus="$event.target.select()" v-model="from"
                                                    label="لە بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" outlined dense
                                                    :rules="rules.date"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="from" @input="menu1 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field @focus="$event.target.select()" v-model="to"
                                                    label="بۆ بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" outlined dense
                                                    :rules="rules.date"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="to" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" width="700" transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs"
                            v-on="on"><v-icon>mdi-plus</v-icon>زیادکردنی کڕیار</v-btn>
                    </template>
                    <v-card>
                        <v-toolbar dark outlined :class="isUpdate ? 'orange accent-2' : 'blue accent-2'" elevation="1">
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn text @click="isUpdate ? update() : create()">
                                    <v-icon>{{ isUpdate ? 'mdi-check' : 'mdi-plus' }}</v-icon> {{ isUpdate ? 'نوێکردنەوەی
                                                                        کڕیار' :
                                    'زیادکردنی کڕیار' }} </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-form ref="customer">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense
                                            label="ناوی کڕیار" outlined v-model="customer.customer_name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" :rules="rules.stringRule" dense
                                            label="مۆبایل" outlined v-model="customer.mobile"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" dense label="قەرزی کۆن" outlined
                                            v-model="customer.debt" type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-card ref="app">
                    <v-data-table :headers="headers" :items="customers" hide-default-footer :items-per-page="20"
                        :loading="loading">
                        <template v-slot:top>
                            <v-text-field @focus="$event.target.select()" v-model="table.search" label="گەڕان"
                                class="mx-4"></v-text-field>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            {{ '$' + item.total.toLocaleString() }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon"
                                    @click="customer = { ...item }; dialog = true">mdi-pencil</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon"
                                    @click="remove(item.customer_id)">mdi-delete</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-delete</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="primary accent-2" id="icon"
                                    @click="view(item.customer_id)">mdi-download</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-download</v-icon>
                            </v-hover>
                        </template>
                    </v-data-table>
                    <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfCustomers / 20)"
                        v-model="table.page"></v-pagination>
                </v-card>
            </v-col>
        </v-row>
        <notification />
    </v-container>
</template>

<script>
import html2canvas from 'html2canvas'
import CustomerInvoice from './Report/CustomerInvoice.vue'
import jsPDF from 'jspdf'
export default {
    components: { CustomerInvoice },
    data() {
        return {
            table: {
                page: 1,
                numberOfCustomers: 0,
                search: ''
            },
            from: '',
            to: '',
            menu1: false,
            menu2: false,
            dateRangeDialog: false,
            customer_id: 0,
            dialog: false,
            customer: {
                customer_id: 0,
                customer_name: '',
                mobile: '',
                debt: 0
            },
            loading: false,
            customers: [],
            headers: [
                { text: 'ناوی کڕیار', value: 'customer_name', align: 'center' },
                { text: 'مۆبایل', value: 'mobile', align: 'center' },
                { text: 'قەرز', value: 'total', align: 'center' },
                { text: 'کردارەکان', value: 'actions', align: 'center' },
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return (v != '' || 'please fill this field')
                    }
                ],
                numberRule: [
                    (v) => {
                        return (v >= 0 || 'negative number invalid')
                    }
                ],
                date: [
                    v => !!v || 'بەروارێک هەڵبژێرە',
                    v => (this.from && this.to ? new Date(this.from) <= new Date(this.to) || 'بەرواری سەرەتا پێویستە بچووکتربێت لە بەرواری کۆتا' : true)]
            }
        }
    },
    methods: {
        async getCustomers() {
            try {
                this.loading = true
                const { data } = await this.axios.get('/customer', { params: { page: this.table.page, search: this.table.search } })
                this.customers = data.customers
                this.table.numberOfCustomers = data.count
                this.loading = false
            } catch (error) {
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەلک ڕوویدا!' })
            }
        },
        async create() {
            if (this.$refs.customer.validate()) {
                try {
                    await this.axios.post('/customer', this.customer)
                    this.reset()
                    this.getCustomers()
                } catch (error) {
                    console.log(error);
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', { type: 'error', text: `${error.response.data.field} is duplicate` })
                    }
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەلک ڕوویدا!' })
                }
            }
        },
        async update() {
            if (this.$refs.customer.validate()) {
                try {
                    const customer = { ...this.customer }
                    await this.axios.patch('/customer/' + customer.customer_id, this.removeProperty(this.customer, ['total']))
                    this.getCustomers()
                    this.reset()
                    this.dialog = false
                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', { type: 'error', text: `${error.response.data.field} is duplicate` })
                    }
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەلک ڕوویدا!' })
                }
            }
        },
        remove(id) {
            this.$confirm('ئایا دڵنیایت لە سڕینەوەی ئەم کڕیارە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/customer/' + id)
                    this.getCustomers()
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەلک ڕوویدا!' })
                }
            })
        },
        async view(customer_id) {
            this.customer_id = customer_id
            this.dateRangeDialog = true
        },
        async search() {
            if (this.$refs.searchForm.validate()) {
                window.open('/report/customer/' + this.customer_id + '/' + this.from + '/' + this.to, '_blank')
            }

        },
        reset() {
            this.customer = {
                customer_id: 0,
                mobile: '',
                debt: 0
            }
            this.$refs.customer.resetValidation();
        },
        removeProperty(obj = {}, fields) {
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        },
        print() {

            this.customer_id = 0
        }
    },
    created() {
        this.getCustomers()
    },
    watch: {
        'table.search': function () {
            this.getCustomers()
        },
        'table.page': function () {
            this.getCustomers()
        },
        dateRangeDialog: function (val) {
            if (!val) {
                this.customer_id = 0
                this.from = ''
                this.to = ''
            }
        }
    },
    computed: {
        isUpdate() {
            return this.customer.customer_id
        }
    }
}
</script>

<style></style>